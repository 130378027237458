<template>
	<var-skeleton :rows="7" :loading="loading">
		<var-card
			title="Set Password"
			class="mb-4"
		>
			<template #extra>
				<var-form ref="form" :disabled="loadingSave">
					<var-input
						type="password"
						placeholder="Password"
						:rules="[v => !!v || 'The password cannot be empty']"
						v-model="formData.password"
						class="mb-4"
					/>
					<var-input
						type="password"
						placeholder="Password Confirmation"
						:rules="[v => !!v || 'The password confirmation cannot be empty']"
						v-model="formData.password_confirmation"
						class="mb-4"
					/>
				</var-form>
				<var-button type="primary" :loading="loadingSave" @click="save">Save</var-button>
			</template>
		</var-card>
	</var-skeleton>
</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";

export default {
	name: "MemberInvitation",
	setup() {
		const formData = reactive({
			password: null,
			password_confirmation: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	created() {
		this.getMemberData()
	},
	data() {
		return {
			loading: true,
			loadingSave: false,
			member: {}
		}
	},
	methods: {
		getMemberData() {
			this.loading = true
			axios.get(process.env.VUE_APP_BASE_URL + '/members/' + this.$route.params.hash + '/invitation')
				.then(response => {
					this.loading = false
					this.member = response.data
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		save() {

			this.form.validate().then(validated => {
				if (!validated) {
					return
				}

				this.loadingSave = true
				axios.post(process.env.VUE_APP_BASE_URL + '/members/' + this.$route.params.hash + '/invitation', this.formData)
					.then(response => {
						this.loadingSave = false
						this.$router.push({name: 'Login'})
					})
					.catch(error => {
						this.loadingSave = false
						console.log(error)
					})
			})
		}
	}
}
</script>

<style scoped>

</style>